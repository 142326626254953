import { createGlobalStyle } from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

import normalize from './normalize'
import grid from './grid'
import { gray, grayscale, orange, red, generateColorClasses, newgray } from './colors'
import {
  generateFontSizeClasses,
  generateFontWeightClasses,
  generateLineHeightClasses,
} from './fonts'
import { generateBorderRadiusClasses } from './radius'
import { generateEllipsisClasses } from './ellipsis'

import 'react-multi-carousel/lib/styles.css'
import '../sass/bootstrap.scss'

const globalStyle = createGlobalStyle`

::-moz-selection { /* Code for Firefox */
  background: ${(props) => props.theme.colors.primary};
}

::selection {
  background: ${(props) => props.theme.colors.primary};
}

  main {
    padding-top: 120px;
    
    @media(min-width: ${breakpoints.lg}) {
      padding-top: 187px;
    }
  }

  ${normalize}
  ${grid}

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Sora';
    font-weight: 600;
  }

  img {
    max-width: 100%;
  }

  a {
    color: ${(props) => props.theme.colors.primary};

    &:hover {
      color: ${(props) => props.theme.colors.secondary400};
    }
  }

  ${generateColorClasses(orange, 'orange')}
  ${generateColorClasses(gray, 'gray')}
  ${generateColorClasses(grayscale, 'grayscale')}
  ${generateColorClasses(newgray, 'newgray')}
  ${generateColorClasses(red, 'red')}
  ${generateFontWeightClasses()}
  ${generateFontSizeClasses()}
  ${generateLineHeightClasses()}
  ${generateBorderRadiusClasses()}
  ${generateEllipsisClasses()}

  .f-sora {
    font-family: 'Sora' !important;
  }

  .f-inter {
    font-family: 'Inter' !important;
  }
`

export default globalStyle
