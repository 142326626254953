import React from 'react'

import { Helmet } from 'react-helmet'

const CookieBanner = () => (
  <Helmet>
    <script
      type="text/javascript"
      src={`https://cdn.cookielaw.org/consent/${process.env.ONETRUST_APP_ID}/OtAutoBlock.js`}
    />
    <script
      type="text/javascript"
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      charSet="UTF-8"
      data-domain-script={process.env.ONETRUST_APP_ID}
    />
    <script type="text/javascript">{`function OptanonWrapper(){} `}</script>
  </Helmet>
)

export default CookieBanner
