import categorias from 'src/assets/data/categories.json'

const themes = categorias.map((categoria) => ({ title: categoria.slug, colors: categoria.colors }))

export type ColorsType = {
  primary: string
  primary300: string
  primary200: string
  primary100: string
  secondary400?: string
  secondary500?: string
  text?: string
  background?: string
}

export type ThemeType = {
  title: string
  colors: ColorsType
}
export default themes
