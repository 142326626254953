import { useState, useEffect } from 'react'

import { debounce } from './utility'

const DEFAULT_DEBOUNCE = 100

/**
 * Custom hook that gets the window width
 * arg throttleValue - value for debounce
 * @return windowWidth - the current width from the page
 */
function useWidthResize(debounceValue?: number) {
  const [width, setWidth] = useState(576)

  useEffect(() => {
    setWidth(window.innerWidth)

    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    debounce(handleResize, debounceValue || DEFAULT_DEBOUNCE)
    window.addEventListener('resize', handleResize, false)
    return () => {
      window.removeEventListener('resize', handleResize, false)
    }
  }, [debounceValue])

  return width
}

export default useWidthResize
