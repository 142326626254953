import React, { useState, MouseEvent, ChangeEvent } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import axios from 'axios'
import { differenceInYears } from 'date-fns'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import useWidth from 'src/hooks/useWidth'
// cdp
import { sendCDPFormData } from 'src/shared/cdp'
// Style
import AcceptTerms from 'src/components/AcceptTerms'
// Images
import errorImg from 'src/assets/images/erro-pig.png'
import BaixeOApp from 'src/assets/images/baixe-o-app-ios-android.png'
import sucesso from 'src/assets/images/sucesso.png'

import MSG from './msgAccount.json'
import { Container, Button } from './style'

interface IFormValues {
  nome: string
  email: string
  celular: string
  cpf: string
  dataNascimento: string
}

function OpenAccountRightForm() {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> =
    useForm<IFormValues>()
  const WIDTH_MD = 1024
  const windowWidth = useWidth(200)
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)
  const [underAge, setUnderAge] = useState(false)
  const [error, setError] = useState(false)
  const [filled, setFilled] = useState(false)
  const [accept, setAccept] = useState(false)
  const [message, setMessage] = useState(MSG.PF)
  const [sendDatalayerEvent] = useDataLayer()

  const sendForm = async (data: IFormValues) => {
    setLoading(true)
    setMessage(MSG.PF)
    const newDate = data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join(',')
    const result = differenceInYears(new Date(), new Date(newDate))

    const formData = {
      ...data,
      cpf: data.cpf.replace(/\D/g, ''),
      celular: data.celular.replace(/\D/g, ''),
      dataNascimento: data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join('-'),
      termoAceito: accept,
      origin: 'portal inter invest',
    }

    try {
      if (result < 18) {
        setUnderAge(true)
        sendDatalayerEvent({
          section: '',
          section_name: '',
          element_name: 'Continuar',
          element_action: 'submit',
          element_previous: '',
          step: 'success',
        })
      } else {
        await axios.put(`${URLS.FORMPOST}/open-account`, formData)
        setSent(true)
        sendCDPFormData({
          formName: 'Abertura de conta InterInvest',
          cpf: data.cpf.replace(/\D/g, ''),
          email: formData.email,
          tituloPagina: '',
        })
      }
    } catch (e) {
      setError(true)
      setLoading(false)
      setFilled(false)
      sendDatalayerEvent({
        section: '',
        section_name: '',
        element_name: 'Continuar',
        element_action: 'submit',
        element_previous: '',
        step: 'error',
      })
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setSent(false)
    setLoading(false)
    setFilled(false)
  }

  const asFilled = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    if (windowWidth < WIDTH_MD) {
      window.location.href = 'https://br6du.app.goo.gl/enBT'
    } else {
      setSent(false)
      setFilled(true)
    }
  }

  const asUnderAge = () => {
    if (windowWidth < WIDTH_MD) {
      window.location.href = 'https://br6du.app.goo.gl/enBT'
    } else {
      window.location.href = '/pra-voce/conta-digital/conta-kids/'
    }
  }

  return (
    <>
      {error && (
        <Container className="d-flex align-items-center">
          <div className="col-12 text-center">
            <h3 className="fs-20 lh-24 mb-5">Ah não! Ocorreu um erro com a sua solicitação...</h3>
            <img src={errorImg} alt="Imagem de erro" />
            <p className="text-grayscale--400 fs-18 lh-22 pt-3">
              Verifique a sua conexão com a internet e tente novamente. <br />
              <button
                type="button"
                onClick={(e) => {
                  handleReturn(e)
                  sendDatalayerEvent({
                    section: '',
                    element_action: 'click button',
                    element_name: 'Clique aqui',
                    section_name: 'Ah não! Ocorreu um erro com a sua solicitação...',
                    redirect_url: '',
                  })
                }}
                title="Clique aqui para voltar"
                className="bg-transparent border-0 fw-600"
              >
                Clique aqui
              </button>
              para voltar.
            </p>
          </div>
        </Container>
      )}
      {sent && (
        <Container className="d-flex align-items-center sent">
          <div className="col-12 text-center">
            <p className="fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--400 fw-600">
              Prontinho! Recebemos os seus dados.
            </p>
            <h3 className="fs-24 lh-28 text-grayscale--500 mb-4 fw-600">
              <span className="d-block">
                Agora <span className="text-orange--extra">baixe nosso app,</span>
              </span>
              e abra sua conta gratuita
            </h3>
            <div className="col-12 pb-2 mx-auto d-lg-none">
              <img src={sucesso} alt="Sucesso" />
            </div>
            <div className="col-12 pb-2 mx-auto d-none d-lg-block">
              <img src={BaixeOApp} alt="Baixe o App Inter" />
            </div>
            <div className="col-12 d-none d-lg-block">
              <hr />
              <p className="fs-14 lh-17 text-left">{message}</p>
            </div>
            <button
              type="button"
              onClick={(evt) => {
                asFilled(evt)
                sendDatalayerEvent({
                  section: '',
                  element_action: 'click button',
                  element_name: 'Baixar o app agora',
                  section_name: 'Ah não! Ocorreu um erro com a sua solicitação...',
                  redirect_url: '',
                })
              }}
              className="fs-16 fw-600 text-white btn bg-orange--linear rounded-2 mx-auto d-lg-none mt-3"
            >
              Baixar o app agora
            </button>
          </div>
        </Container>
      )}
      {underAge && (
        <Container className="d-flex align-items-center under-age">
          <div className="col-12 text-md-center text-lg-left">
            <h3 className="fs-16 lh-20 text-grayscale--400 fw-400 fw-md-600">
              Vimos que você tem menos de 18 anos.
            </h3>
            <p className="fs-20 lh-23 fs-md-24 lh-md-28 text-grayscale--500 mb-4 fw-600">
              <span className="d-block">
                Conheça nossa
                <button
                  type="button"
                  className="kids-button"
                  onClick={() => {
                    asUnderAge()
                    sendDatalayerEvent({
                      section: '',
                      element_action: 'click button',
                      element_name: 'Conta Kids',
                      section_name: 'Ah não! Ocorreu um erro com a sua solicitação...',
                      redirect_url: '',
                    })
                  }}
                >
                  {' '}
                  Conta Kids
                </button>
                ,
              </span>
              que também é completa, digital e gratuita.
            </p>
            <div className="col-12 pb-2 mx-auto d-lg-none text-center">
              <img src={sucesso} alt="Sucesso" />
            </div>
            <div className="col-12 pb-2 mx-auto d-none d-lg-block text-center">
              <img src={BaixeOApp} alt="Baixe o App Inter" />
            </div>
            <div className="col-12 d-none d-lg-block px-0">
              <hr />
              <p className="fs-14 lh-17 text-left">
                Abra a câmera do seu celular e aponte para o QR Code acima. É só baixar o app do
                Inter e clicar em ”Abrir conta completa e gratuita”. É rápido e fácil!
              </p>
              <p className="fs-14 lh-17 text-left">
                De acordo com nossa Política de Privacidade, não armazenamos dados de menores de 18
                anos sem o consentimento dos responsáveis.
              </p>
            </div>
          </div>
        </Container>
      )}
      {filled && (
        <Container className="d-flex align-items-center filled">
          <div className="col-12 text-center">
            <h3 className="fs-20 lh-24 fw-600 text-grayscale--500">
              Baixe o app e vá em <br /> ”Abrir uma conta gratuita”
            </h3>
            <p className="fs-16 lh-20 text-grayscale--400 mb-5">{MSG.filled}</p>
            <div className="col-12">
              <img src={BaixeOApp} alt="Baixe o App Inter" />
            </div>
          </div>
        </Container>
      )}
      <Container
        className={`${
          error || sent || filled || underAge ? 'd-none' : 'd-flex'
        } align-items-center`}
      >
        <div className="row">
          <div className="col-12 text-center py-4 pt-lg-0">
            <h2 className="fs-20 text-left text-md-center mb-2 lh-24 mt-3 text-grayscale--500">
              Abra agora sua Conta Digital
            </h2>
          </div>
          <div className="col-12">
            <form className="form--default" name="open_account" onSubmit={handleSubmit(sendForm)}>
              <div className="body-form">
                <div className="col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1">
                  <label htmlFor="nome" className="fs-12 lh-15 fw-600">
                    Nome
                  </label>
                  <input
                    ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) =>
                          Validations.name(value) || 'Por favor, digite seu nome completo',
                      },
                    })}
                    name="nome"
                    id="nome"
                    type="text"
                    maxLength={100}
                    placeholder="Digite seu nome"
                  />
                  {errors.nome && (
                    <p className="fs-12 text-red--base mb-0 text-right">{errors.nome.message}</p>
                  )}
                </div>
                <div className="col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1">
                  <label htmlFor="celular" className="fs-12 lh-15 fw-600">
                    Telefone
                  </label>
                  <input
                    ref={register({
                      required: 'Digite um telefone válido',
                      pattern: {
                        value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                        message: 'Telefone inválido',
                      },
                    })}
                    name="celular"
                    id="celular"
                    type="tel"
                    placeholder="Digite seu telefone"
                    maxLength={15}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setValue('celular', Masks.MaskPHONE(event.currentTarget.value))
                    }
                  />
                  {errors.celular && (
                    <p className="fs-12 text-red--base mb-0 text-right">{errors.celular.message}</p>
                  )}
                </div>
                <div className="col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1">
                  <label htmlFor="email" className="fs-12 lh-15 fw-600">
                    E-mail
                  </label>
                  <input
                    ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    name="email"
                    id="email"
                    type="text"
                    placeholder="Digite seu e-mail"
                  />
                  {errors.email && (
                    <p className="fs-12 text-red--base mb-0 text-right">{errors.email.message}</p>
                  )}
                </div>
                <div className="col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1">
                  <label htmlFor="cpf" className="fs-12 lh-15 fw-600">
                    CPF
                  </label>
                  <input
                    ref={register({
                      required: 'Digite um CPF válido',
                      validate: {
                        isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                      },
                    })}
                    name="cpf"
                    id="cpf"
                    type="tel"
                    placeholder="Digite seu CPF"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setValue('cpf', Masks.MaskCPF(event.currentTarget.value))
                    }
                  />
                  {errors.cpf && (
                    <p className="fs-12 text-red--base mb-0 text-right">{errors.cpf.message}</p>
                  )}
                </div>
                <div className="col-12 d-flex flex-column px-0 px-md-3 pb-1">
                  <label htmlFor="dataNascimento" className="fs-12 lh-15 fw-600">
                    Data de Nascimento
                  </label>
                  <input
                    ref={register({
                      required: 'Digite uma data válida',
                      validate: {
                        isDate: (value: string) => Validations.dateValid(value) || 'Data Inválida',
                      },
                    })}
                    name="dataNascimento"
                    id="dataNascimento"
                    type="tel"
                    placeholder="dd/mm/aaaa"
                    maxLength={10}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setValue('dataNascimento', Masks.MaskDATE(event.currentTarget.value))
                    }
                  />
                  {errors.dataNascimento && (
                    <p className="fs-12 text-red--base mb-0 text-right">
                      {errors.dataNascimento.message}
                    </p>
                  )}
                </div>
                <div className="col-12 d-flex flex-column px-0 px-md-3">
                  <AcceptTerms accept={accept} setAccept={setAccept} />
                </div>
                <div className="col-12 text-center">
                  <Button
                    type="submit"
                    title="Enviar contato"
                    disabled={!accept || loading}
                    className="mx-auto"
                  >
                    {loading ? 'Enviando...' : 'Continuar'}
                  </Button>
                </div>
              </div>
            </form>
            <div className="text-center pt-3">
              <span className="fs-14 lh-18 text-grayscale--400">
                Já preencheu?
                <button
                  type="button"
                  onClick={(evt) => {
                    asFilled(evt)
                    sendDatalayerEvent({
                      section: '',
                      element_action: 'click button',
                      element_name: 'Agora baixe o app',
                      section_name: 'Ah não! Ocorreu um erro com a sua solicitação...',
                      redirect_url: '',
                    })
                  }}
                  className="text-orange--extra fw-600 bg-transparent border-0"
                >
                  Agora baixe o app
                </button>
              </span>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default OpenAccountRightForm
