import styled from 'styled-components'
import { gray } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Stocks = styled.div`
  background: ${gray[100]};
  background: #f5f6fa;
  border-radius: 8px;
  margin-top: 24px;
  display: flex;
  height: 56px;
  overflow: hidden;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 32px;
  }

  div.marquee-container {
    overflow: hidden !important;
  }
`

export const Tape = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 16px;
  padding: 0 10px;
  height: 17px;

  & + div {
    border-left: 1px dashed #dedfe4;
  }

  .papel {
    margin-right: 16px;
  }

  .preco {
    margin-right: 20px;
  }

  .variacao {
    display: flex;
    align-items: center;

    &-price {
      margin-left: 4px;
      color: ${(props) => (props.color === 'up' ? '#00AA4F' : '#F56A50')};
    }
  }
`
