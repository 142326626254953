import React, { useState, ReactNode, useEffect, useCallback } from 'react'

import { PageContextType } from 'src/types/PageContext'
// Components
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import CookieBanner from 'src/components/CookieBanner'
import StocksTape from 'src/components/StocksTape'
import Newsletter from 'src/components/Newsletter/Modal'
import CDP from 'src/components/cdp'
// Styles and Theme
import Themes from 'src/styles/themes'
import Main from 'src/styles/themes/main'
import GlobalStyle from 'src/styles/global'
import AddThis from 'src/components/AddThis'
import { ThemeProvider } from 'styled-components'
import FullScreenModal from 'src/components/FullScreenModal'
// Types
type Layout = {
  pageContext?: PageContextType
  children: ReactNode
  className?: string
  isHome?: boolean
}

function Layout({ children, className, pageContext, isHome }: Layout) {
  const [openModalNewsletter, setOpenModalNewsletter] = useState(false)

  const getActiveTheme = useCallback(() => {
    const activeTheme = Object.values(Themes).find((theme) => {
      if (!pageContext || !pageContext.categoria) {
        return false
      }
      const categoria = JSON.stringify(pageContext.categoria)
      if (categoria.includes(theme.title)) {
        return theme
      }
      return false
    })
    return activeTheme || Main
  })
  const [theme, setTheme] = useState(getActiveTheme())

  useEffect(() => {
    setTheme(getActiveTheme())
  }, [getActiveTheme])

  // Abrir modal com url
  const clearLocation = (location: string) => {
    const url = new URL(location)
    const newsletter = url.searchParams.get('newsletter')
    return newsletter
  }

  useEffect(() => {
    const location = clearLocation(window.location.href)
    if (location) {
      setOpenModalNewsletter(true)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CookieBanner />
      <GlobalStyle />
      <Header className={className} pageContext={pageContext} theme={theme} isHome={isHome} />
      <main>
        <StocksTape />
        {children}
        <FullScreenModal open={openModalNewsletter} setOpen={setOpenModalNewsletter} padding>
          <Newsletter setOpen={setOpenModalNewsletter} />
        </FullScreenModal>
      </main>
      <Footer />
      <AddThis bootstrap />
      <CDP />
    </ThemeProvider>
  )
}

export default Layout
