import React from 'react'

// Components
import FollowTheInset from '../FollowTheInset'
import KnowTheInter from '../KnowTheInter'
import WeekHightlight from '../WeekHightlight'
import FooterJSON from './Footer.json'
import GetApp from '../GetApp'

const Footer = () => (
  <section>
    <div className="container border-top pt-4 pt-md-5">
      <div className="row pt-lg-3">
        <div className="col-12 col-md-3 pb-4 mb-3 order-last order-md-first">
          <FollowTheInset />
        </div>

        {FooterJSON.map((item) => (
          <div className="col-6 col-md-2 pb-4 mb-3" key={item.id}>
            <WeekHightlight title={item.title} data={item.data} />
          </div>
        ))}
        <div className="col-6 col-md-3 pb-4 mb-3">
          <KnowTheInter />
        </div>
        <div className="col-12 d-md-none">
          <GetApp />
        </div>
      </div>
    </div>
  </section>
)

export default Footer
