import { useEffect, useState } from 'react'

import { EventTypes, IDataLayerHeaderParams, IDataHeader } from './typesUseDataLayer'

/**
 *  Data Layer Event
 *  Saiba Mais: https://confluence.bancointer.com.br/pages/viewpage.action?pageId=188390035#Novopadr%C3%A3odetagueamento-PARADEVS
 */

const useDataLayerHeader = (event?: EventTypes) => {
  const [dataLayerEvent, setDataLayerEvent] = useState<EventTypes>('ga_event_header')

  useEffect(() => {
    event && setDataLayerEvent(event)
  }, [dataLayerEvent])

  const returnDataLayer = (data: IDataLayerHeaderParams) => {
    const {
      section,
      sub_section,
      element_action,
      element_name,
      redirect_url,
      element_search,
      c_page,
    }: IDataLayerHeaderParams = data

    const dataLayer: IDataHeader = {
      event: dataLayerEvent,
      params: {
        section: section || 'null',
        sub_section: sub_section || 'null',
        element_action,
        element_name,
        c_page: c_page || window.location.href,
        redirect_url: redirect_url || 'null',
        element_search: element_search || 'null',
      },
    }

    return dataLayer
  }

  const sendDatalayerHeaderEvent = (dataLayer: IDataLayerHeaderParams) => {
    const handleDataLayerEvent = returnDataLayer(dataLayer)

    if (window && window.dataLayer) {
      window.dataLayer.push(handleDataLayerEvent)
    }

    return window.dataLayer
  }

  return [sendDatalayerHeaderEvent]
}

export default useDataLayerHeader
