import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

import { grayscale } from '../../../styles/colors'
import { borderRounded } from '../../../styles/radius'

export const InputText = styled.input`
  font-family: 'Inter';
  border: none;
  border-radius: ${borderRounded['8']};
  background-color: ${grayscale['100']};
  color: ${grayscale['500']};
  font-weight: 700;
  font-size: 14px;
  height: 48px;
  width: 315px;
  padding: 15px 38px 15px 16px;
  z-index: 10;
  line-height: 17px;

  &::placeholder {
    color: #b6b7bb;
  }
  @media (min-width: ${breakpoints.md}) {
    margin-right: 10px;
  }
  &:focus {
    outline: none;
  }
`

export const SearchContainer = styled.div`
  position: relative;
  width: 315px;
  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`
