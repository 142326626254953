import React, { useEffect } from 'react'
// import Helmet from 'react-helmet'

const CDP = () => {
  const handleLoad = () => {
    window.SalesforceInteractions.init({
      consents: [
        {
          provider: 'OneTrust',
          purpose: window.SalesforceInteractions.ConsentPurpose.Tracking,
          status: window.SalesforceInteractions.ConsentStatus.OptIn,
        },
      ],
    })
  }
  useEffect(() => {
    if (window && window.SalesforceInteractions) {
      window.SalesforceInteractions.init({
        consents: [
          {
            provider: 'OneTrust',
            purpose: window.SalesforceInteractions.ConsentPurpose.Tracking,
            status: window.SalesforceInteractions.ConsentStatus.OptIn,
          },
        ],
      })
    }
    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        handleLoad()
      }
    })
    return () => {
      window.removeEventListener('visibilitychange', handleLoad)
    }
  })

  useEffect(() => {
    if (window) {
      const urls = JSON.parse(window.localStorage.getItem('paths'))

      if (urls) {
        const paths = urls
        paths.push(window.location.href)
        window.localStorage.setItem('paths', JSON.stringify(paths))
      } else {
        const paths = []
        paths.push(window.location.href)
        window.localStorage.setItem('paths', JSON.stringify(paths))
      }
    }
  }, [])

  return (
    // <Helmet>
    //   <script src='https://cdn.c360a.salesforce.com/beacon/c360a/c0891bd3-6f31-4f53-8a0b-59bd4d041303/scripts/c360a.min.js' />
    // </Helmet>
    <div />
  )
}
export default CDP
