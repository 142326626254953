import React, { useState, useRef, ChangeEvent } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerHeader'
import { navigate } from 'gatsby'
import OrangeIcon from 'src/components/Icon/OrangeDsIcon'

import { InputText, SearchContainer } from './style'

const Search = () => {
  const [inputValue, setInputValue] = useState('')
  const [sendDatalayerEvent] = useDataLayer()
  const inputRef = useRef<HTMLInputElement>(null)
  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setInputValue(evt.target.value)
  }
  const handleSearch = (event: { key: string }) => {
    if (event.key !== 'Enter') {
      return
    }
    sendDatalayerEvent({
      section: '',
      element_action: 'click button',
      element_name: 'Busca',
      redirect_url: `${window.location.href}/search?q=${encodeURIComponent(inputValue)}`,
      element_search: inputValue,
    })
    navigate(`/search?q=${encodeURIComponent(inputValue)}`)
  }
  return (
    <SearchContainer>
      <InputText
        placeholder="Pesquisar"
        type="text"
        value={inputValue}
        ref={inputRef}
        onKeyDown={handleSearch}
        onChange={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
      />
      <OrangeIcon size="MD" icon="search" color="#FF7A00" width={24} height={24} />
    </SearchContainer>
  )
}
export default Search
