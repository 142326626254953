import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerFooter'

import { KnowTheInterContent } from './style'

const KnowTheInter = () => {
  const [sendDatalayerEvent] = useDataLayer()
  return (
    <KnowTheInterContent>
      <a
        className="fs-12 lh-15 fs-md-14 lh-md-17 text-grayscale--500 fw-600"
        href="/quem-somos"
        onClick={() =>
          sendDatalayerEvent({
            section: '',
            element_action: 'click button',
            element_name: '',
            redirect_url: `${process.env.BASE_UR}/quem-somos`,
          })
        }
      >
        Quem Somos
      </a>
      <a
        className="fs-12 lh-15 fs-md-14 lh-md-17 text-grayscale--500 fw-600"
        href="/aprenda-a-investir"
        onClick={() =>
          sendDatalayerEvent({
            section: '',
            element_action: 'click button',
            element_name: 'Quem Somos',
            redirect_url: `${process.env.BASE_UR}/aprenda-a-investir`,
          })
        }
      >
        Aprenda a Investir
      </a>
    </KnowTheInterContent>
  )
}

export default KnowTheInter
