import styled from 'styled-components'
import { gray } from 'src/styles/colors'

export const Section = styled.section`
  background: #f5f6fa;

  input {
    background: #fff;
    border-radius: 8px;
    width: 100%;
    height: 48px;
    padding: 16px;

    &::placeholder {
      color: #b6b7bb;
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
    }
  }

  button {
    background: #ff7a00;
    border-radius: 8px;
    height: 48px;
    width: 100%;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    color: #fff;

    &[disabled] {
      background: #dedfe4;
      color: #b6b7bb;
    }
  }
`
export const Msg = styled.div`
  h4 {
    color: ${gray[800]};
  }
  p {
    color: ${gray[600]};
  }
`
