import styled from 'styled-components'

import { device } from '../../styles/breakpoints'
import { orange, gray, white } from '../../styles/colors'

export const Checkbox = styled.div`
  margin: 10px 0;

  input {
    display: contents;
  }

  .form-label-check {
    position: relative;
    padding-left: 30px;
    margin-bottom: 0;

    &::before,
    &::after {
      display: block;
      position: absolute;
      content: '';
    }

    &::before {
      border: 2px solid ${gray['500']};
      border-radius: 3px;
      height: 20px;
      left: 0;
      top: 2px;
      width: 20px;
    }

    a {
      &:hover {
        color: ${gray['600']};
      }
    }
  }

  .form-input-check {
    height: 0 !important;
    opacity: 0;
    width: auto;

    &:disabled ~ label {
      opacity: 0.6;
    }
    &:checked ~ label {
      &::before {
        background: linear-gradient(7.83deg, ${orange.dark} 0%, ${orange.base} 100%);
        border: 1px solid ${orange.dark};
      }
      &::after {
        left: 7px;
        top: 5px;
        width: 6px;
        height: 10px;
        border: solid ${white};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);

        @media ${device.tablet} {
          top: 5px;
        }
      }
    }
  }
`
