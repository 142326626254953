import React, { useState, useEffect, useLayoutEffect } from 'react'

import useWidth from 'src/hooks/useWidth'
import axios from 'axios'
import Marquee from 'react-fast-marquee'
// Components
import OragenIconDs from 'src/components/Icon/OrangeDsIcon'

// Styles
import { Stocks, Tape } from './style'

const WIDTH_MD = 768

type TapeProp = {
  icone: string
  papel: string
  preco: string
  variacao: string
}

type SpotlightProps = {
  descricao: string
  items: TapeProp[]
  tipo: string
}

const StocksTape = () => {
  const [data, setData] = useState<TapeProp[]>([])
  const [isMobile, setIsMobile] = useState(true)
  const windowWidth = useWidth(300)
  useLayoutEffect(() => {
    setIsMobile(windowWidth <= WIDTH_MD)
  }, [windowWidth])

  function handleIndex(index: number) {
    return index
  }

  async function dataLoad() {
    try {
      const destaques: SpotlightProps[] = []
      const response = await axios.get(`${process.env.QUOTES_AND_INDICES}`)
      response.data.destaques.map((item: SpotlightProps) =>
        item.items.map((tape: TapeProp) => destaques.push(tape)),
      )
      setData(destaques)
    } catch (error) {
      setData([])
    }
  }

  useEffect(() => {
    dataLoad()
  }, [])

  return (
    <section>
      <div className="container">
        {!!data.filter((item) => item.papel.length < 7).length && (
          <Stocks>
            <Marquee speed={50} gradient={false} pauseOnHover={!isMobile} pauseOnClick={isMobile}>
              {data
                .filter((stock: TapeProp) => stock.papel.length < 7)
                .map((item: TapeProp, index: number) => (
                  <Tape color={item.icone.toLowerCase()} key={handleIndex(index)}>
                    <span className="papel">{item.papel}</span>
                    <span className="preco">{item.preco}</span>
                    <div className="variacao">
                      {item.icone.toLowerCase() === 'up' ? (
                        <OragenIconDs size="XS" color="#00AA4F" icon="chevron-less" />
                      ) : (
                        <OragenIconDs size="XS" color="#F56A50" icon="chevron-more" />
                      )}
                      <span className="variacao-price">{item.variacao}</span>
                    </div>
                  </Tape>
                ))}
            </Marquee>
          </Stocks>
        )}
      </div>
    </section>
  )
}

export default StocksTape
