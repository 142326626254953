import React from 'react'

import OrangeIcon from '../Icon/OrangeDsIcon'
import * as S from './style'

export interface IModal {
  children: React.ReactNode
  open: boolean
  setOpen: (value: boolean) => boolean | void
  closeButton?: boolean
  padding?: boolean
}

const FullScreenModal = ({ children, open, setOpen, closeButton = true, padding }: IModal) => {
  function handleClose() {
    setOpen(false)
  }

  return open ? (
    <S.ModalWrapper>
      <S.Modal padding={padding}>
        {closeButton && (
          <S.CloseButton onClick={() => handleClose()}>
            <OrangeIcon icon="exit" size="LG" color="#FF7A00" />
          </S.CloseButton>
        )}
        {children}
      </S.Modal>
      <S.ModalBackdrop onClick={() => setOpen(false)} />
    </S.ModalWrapper>
  ) : null
}

export default FullScreenModal
