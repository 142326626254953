import { orange, white, grayscale } from '../colors'

export default {
  title: 'main',

  colors: {
    primary: `${orange.base}`,
    primary300: `#FFDFC2`,
    primary200: `#FFE4CC`,
    primary100: `#FFF2E5`,
    secondary400: `${grayscale['400']}`,
    secondary500: `${grayscale['500']}`,
    text: `${white}`,
    background: `${orange.base}`,
  },
}
