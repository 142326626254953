import React from 'react'

import FloatingSocialMedia from 'src/components/FloatingSocialMedia'
import FloatingSocialMediaSharer from 'src/components/FloatingSocialMedia/assets/data/FloatingSocialMedia.json'

const FollowTheInset = () => (
  <>
    <p className="fs-10 mb-3 fw-700 text-grayscale--500">Siga o Portal Inter Invest</p>
    <FloatingSocialMedia items={FloatingSocialMediaSharer} sharer={false} direction="row" />
  </>
)

export default FollowTheInset
