import breakpoints from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Container = styled.div`
  background: #fff;
  right: 0;
  top: 0;
  overflow-y: auto;
  width: 100%;
  padding: 0 40px;

  @media (min-width: ${breakpoints.md}) {
    padding: 0 100px;
  }

  input {
    background: #f5f6fa;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    padding: 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    &::placeholder {
      color: #b6b7bb;
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
    }
  }

  .border {
    height: 8px;
    background: #f5f6fa;
    width: calc(100% + 80px);
    border: none !important;
    padding: 0;
    margin-left: -40px;

    @media (min-width: ${breakpoints.md}) {
      margin-left: -100px;
      width: calc(100% + 200px);
    }
  }

  .input-icon {
    position: relative;
    height: 48px;

    .icon {
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }

  .border-dotted {
    border-bottom: 1px dashed #dedfe4;
  }
`
export const Button = styled.button`
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  text-align: left;

  &:focus {
    outline: none;
  }
`
export const ButtonSubmit = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: #ff7a00;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: #fff;

  &:focus {
    outline: none;
  }

  &[disabled] {
    background-color: #dedfe4;
    color: #b6b7bb;
  }
`

export const ButtonCancel = styled.button`
  border: 1px solid #ff7a00;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  color: #ff7a00;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  background: #fff;

  &:focus {
    outline: none;
  }
`
export const Check = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 4px;
  background-color: #ff7a00;
  position: relative;
  margin: 5px;

  span {
    position: absolute;
    width: 14px;
    height: 2px;
    border-radius: 25px;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
`
