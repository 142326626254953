import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

import { grayscale } from '../../styles/colors'
import { borderRounded } from '../../styles/radius'

export const InputText = styled.input`
  border: none;
  border-radius: ${borderRounded['8']};
  background-color: ${grayscale['100']};
  color: ${grayscale['500']};
  font-weight: 700;
  font-size: 12px;
  height: 48px;
  padding: 15px 40px 15px 16px;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    margin-right: 10px;
  }

  &:focus {
    outline: none;
  }
`

export const IconSearch = styled.div`
  float: right;
  cursor: pointer;
  padding-right: 10px;

  @media (min-width: ${breakpoints.md}) {
    position: relative;
    right: 10px;
    margin-top: 2px;
  }
`

export const IconClose = styled.div`
  cursor: pointer;
  float: right;
  position: absolute;
  right: 16px;
  top: 12px;

  @media (min-width: ${breakpoints.md}) {
    right: 27px;
  }
`
