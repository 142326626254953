// eslint-disable-next-line consistent-return
const getButtonEvent = () => {
  if (window) {
    const event = window.dataLayer
      .map((item) => {
        const el = item['gtm.element']
        if (el) {
          return el
        }
        return false
      })
      .filter((item) => item.tagName === 'BUTTON')
      .slice(-3)[0]

    return event ? event.innerText : null
  }
}

export const sendCDPEvent = async (event, params) => {
  if (window && window.SalesforceInteractions) {
    window.SalesforceInteractions.init({
      consents: [
        {
          provider: 'OneTrust',
          purpose: window.SalesforceInteractions.ConsentPurpose.Tracking,
          status: window.SalesforceInteractions.ConsentStatus.OptIn,
        },
      ],
    })
    await window.SalesforceInteractions.sendEvent({
      interaction: {
        name: event,
        eventType: event,
        ...params,
        botao: getButtonEvent(),
      },
    })
  }
}

// captura eventos
export const sendCDPFormData = ({
  formName,
  cpf,
  email,
  categoria = '',
  subCategoria = '',
  tituloPagina,
}) => {
  if (window) {
    const urls = JSON.parse(window.localStorage.getItem('paths')) || ''
    urls.map((item) =>
      sendCDPEvent('eventsForm', {
        formName,
        cpf,
        email,
        url: item,
        categoria,
        subCategoria,
        tituloPagina,
      }),
    )
    window.localStorage.removeItem('urls')
    window.localStorage.removeItem('paths')
  }
}
