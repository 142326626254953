import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

import lottie from 'lottie-web'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerHeader'
import articlesJSON from 'src/assets/data/articles.json'
import categoriesJSON from 'src/assets/data/categories.json'
import SearchDesk from 'src/components/Search/SearchDesk'
import OrangeDsIcon from 'src/components/Icon/OrangeDsIcon'
import { Link } from 'gatsby'
import { PageContextType } from 'src/types/PageContext'
import SearchMobile from 'src/components/Search'
import { ICategory } from 'src/types/Category'
import { ThemeType } from 'src/styles/themes'
import ModalNewsletter from 'src/components/Newsletter/Modal'

import useScroll from '../../hooks/useScroll'
import useOrigin from '../../hooks/useOrigin'
import OpenAccountRightForm from '../OpenAccount'
import FullScreenModal from '../FullScreenModal'
import '@inter/inter-ui/dist/inter-ui.css'
import interLogoAnimation from './assets/data/animations/logo/data.json'
import { Button, HeaderSection, Logo, Nav } from './style'

type HeaderProps = {
  pageContext: PageContextType
  theme: ThemeType
  isHome?: boolean
}
const categories = Object.keys(articlesJSON)
const categoriesToMenu = categoriesJSON
  .map((item: ICategory) => {
    const category = categories.find(
      (slug) => item.slug === slug || item.id === '1f3a9c83-9600-4620-a28e-fdd9727cdfe7',
    )

    if (category) {
      return {
        id: item?.slug,
        name: item?.name,
        slug: `/${item?.slug}`,
        icon: item?.icon,
      }
    }
  })
  .filter((item) => item !== undefined)

const categoryOrder = categoriesJSON
  .map((slug) => {
    return categoriesToMenu.find((item) => item.id === slug.slug)
  })
  .filter((item) => item !== undefined)

const menuObject = [...categoryOrder]

const Header = ({ pageContext, theme, isHome }: HeaderProps) => {
  const [openModal, setOpenModal] = useState(false)
  const [sendDatalayerEvent] = useDataLayer()
  const [open, setOpen] = useState(false)
  const menuListElement = useRef(null)
  const logoContainerRef = useRef(null)

  useEffect(() => {
    const container = logoContainerRef.current
    const anim = lottie.loadAnimation({
      container,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: interLogoAnimation,
    })

    return () => {
      anim.destroy()
    }
  }, [])

  const handleOpen = (e: React.MouseEvent) => {
    e.preventDefault()
    setOpenModal(true)
  }

  const scrollPositionY = useScroll(100)
  const fixedHeader = scrollPositionY > 70
  const origin = useOrigin().split('/')
  useLayoutEffect(() => {
    if (!menuListElement || !menuListElement.current) {
      return
    }
    menuListElement.current.scrollLeft = sessionStorage.getItem('scrollPosition')
  }, [])

  function getScrollPosition(event) {
    sessionStorage.setItem('scrollPosition', event.currentTarget.scrollLeft)
  }

  const tabName = menuObject.map((item) => (
    <li key={item.id} className={`fs-14 ${item.slug.split('/')[1] === origin[1] ? 'active' : ''}`}>
      <Link
        to={item.slug}
        title={item.name}
        onClick={() => {
          sendDatalayerEvent({
            section: item?.name,
            element_action: 'click button',
            element_name: item.name,
            redirect_url: item?.slug,
            c_page: `${process.env.BASE_URL}${item.slug.replace('/', '')}`,
          })
        }}
      >
        {item.name}
      </Link>
    </li>
  ))

  const tabDesk = menuObject.map((item) => (
    <li
      key={item.id}
      className={`fs-14 col ${item.slug.split('/')[1] === origin[1] ? 'active' : ''}`}
    >
      <Link
        to={item.slug}
        title={item.name}
        onClick={() => {
          sendDatalayerEvent({
            section: item?.name,
            element_action: 'click button',
            element_name: item.name,
            redirect_url: item?.slug,
            c_page: `${process.env.BASE_URL}${item.slug.replace('/', '')}`,
          })
        }}
      >
        <div>
          <OrangeDsIcon
            size="MD"
            width={24}
            height={24}
            icon={item.icon}
            color={item.slug.split('/')[1] === origin[1] ? theme.colors.primary : '#FF7A00'}
          />
        </div>
        <span className="fs-16 lh-19 mb-auto d-block mt-2">{item.name}</span>
      </Link>
    </li>
  ))

  return (
    <>
      <HeaderSection
        categoryMenu={!(pageContext && pageContext.categoryMenu)}
        className={`${fixedHeader ? 'drop-menu' : 'menu'}`}
      >
        <div className="container">
          <div className="row align-items-center position-relative">
            <div className="col-12 px-0 d-flex align-items-center mb-lg-4">
              <Logo className="col-5 col-md-3 px-0 d-flex align-items-center">
                <Link
                  to="/"
                  title="Página Principal"
                  className="ml-n3 d-block"
                  onClick={() => {
                    sendDatalayerEvent({
                      section: pageContext.categoria ?? 'null',
                      element_action: 'click button',
                      element_name: 'Inter Invest',
                      redirect_url: process.env.BASE_URL,
                      c_page: '',
                    })
                  }}
                >
                  <div ref={logoContainerRef} />
                  {isHome && <h1 className="sr-only">Inter Invest</h1>}
                </Link>
              </Logo>
              <div className="col-7 col-md-9 px-0 px-md-3 d-flex align-items-center justify-content-md-end">
                <div className="search mr-3 d-none d-lg-block">
                  <SearchDesk />
                </div>
                <div className="d-lg-none px-0 px-md-3 col-md-8">
                  <div className="search-mobile">
                    <SearchMobile color="#FF7A00" />
                  </div>
                </div>
                <div col-md4>
                  <Button
                    onClick={(e: React.MouseEvent) => {
                      handleOpen(e)
                      sendDatalayerEvent({
                        section: pageContext.categoria ?? 'null',
                        element_action: 'click button',
                        element_name: 'Abra a sua conta',
                      })
                    }}
                    className="mr-3 d-none d-md-block"
                  >
                    Abra a sua conta
                  </Button>
                </div>
                <Button
                  onClick={() => {
                    setOpen(true)
                    sendDatalayerEvent({
                      section: pageContext.categoria ?? 'null',
                      element_action: 'click button',
                      element_name: 'Assinar Newsletter',
                    })
                  }}
                  className="inverse d-none d-lg-block"
                >
                  Assinar Newsletter
                </Button>
              </div>
            </div>
          </div>
          {!(pageContext && pageContext.categoryMenu) && (
            <div className={`row border-md mx-0 ${fixedHeader && 'drop-menu'}`}>
              <div className="col-8 col-lg-12 d-none d-lg-block mt-lg-1 px-0">
                <Nav>
                  <ul className="menu">{tabDesk}</ul>
                </Nav>
              </div>
              <div className="col-12 d-lg-none">
                <Nav>
                  <ul className="menu-mobile" ref={menuListElement} onScroll={getScrollPosition}>
                    {tabName}
                  </ul>
                </Nav>
              </div>
            </div>
          )}
        </div>
      </HeaderSection>
      <FullScreenModal open={openModal} setOpen={setOpenModal}>
        <OpenAccountRightForm />
      </FullScreenModal>
      <FullScreenModal open={open} setOpen={setOpen} padding>
        <ModalNewsletter
          setOpen={setOpen}
          page={isHome ? 'home' : pageContext && pageContext.categoria}
        />
      </FullScreenModal>
    </>
  )
}
export default Header
