import React, { useContext } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerFooter'
import { PageContextType } from 'src/types/PageContext'
import { ThemeContext } from 'styled-components'

import OrangeIcon from '../Icon/OrangeDsIcon'
// Assets
import { UL } from './style'

type FloatingSocialMediaProps = {
  icon: string
  title: string
  url: string
}

type Direction = {
  items: FloatingSocialMediaProps[]
  sharer?: boolean
  pageContext?: PageContextType
  direction: 'row' | 'col'
  justifyContent?: string
  pageUrl?: string
}

const isBrowser = typeof window !== 'undefined'
const whatsapp = 'whatsapp'

const FloatingSocialMedia = ({
  items,
  sharer,
  pageContext,
  direction,
  justifyContent,
  pageUrl,
}: Direction) => {
  const { colors } = useContext(ThemeContext)
  const [sendDatalayerEvent] = useDataLayer()

  function getUrlSharer(item: FloatingSocialMediaProps) {
    if (!isBrowser && !sharer) {
      return `${item.url}`
    }
    if (!sharer && isBrowser) {
      return `${item.url}`
    }
    if (pageUrl) {
      if (item.icon === whatsapp && pageContext?.fileContents) {
        return `${item.url}${pageContext?.fileContents.title}${pageUrl}`
      }
      return `${item.url}${pageUrl}`
    }
    return item.url
  }

  return (
    <>
      <UL
        className={direction === 'row' ? `d-flex flex-row ${justifyContent}` : 'd-flex flex-column'}
        absolute={direction === 'row' ? 'inherit' : 'absolute'}
      >
        {items.map((item: FloatingSocialMediaProps) => (
          <li key={item.title}>
            <a
              href={getUrlSharer(item)}
              target="blank"
              title={item.title}
              onClick={() => {
                sendDatalayerEvent({
                  section: '',
                  element_action: 'click button',
                  element_name: item.title,
                  redirect_url: item.url,
                })
              }}
            >
              <OrangeIcon color={colors.primary} icon={item.icon} size="LD" />
            </a>
          </li>
        ))}
      </UL>
    </>
  )
}

export default FloatingSocialMedia
