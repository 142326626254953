import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

type buttonPorps = {
  disabled: boolean
}

export const Container = styled.div`
  background-color: ${white};

  h3 {
    font-family: 'Sora';
  }

  .kids-button {
    font-family: 'Sora';
    color: ${orange.base};
    background-color: white;
    cursor: pointer;
    font-weight: 600;
    margin-left: 5px;
  }

  img {
    max-width: 185px;
  }

  .form--default {
    label {
      color: #6a6c72;
      margin-bottom: 4px;
    }

    input {
      background: #f5f6fa;
      border-radius: 4px;
      border: none;
      color: #161616;
      font-size: 14px;
      line-height: 17px;
      padding: 10px;
    }
  }

    .form-label-check {
      &::before {
        top: 15px;
      }
    }
    .form-input-check {
      &:checked ~ label {
        &::after {
          top: 18px;
        }
      }
    }
  }

  &.sent {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 70px;
      padding-right: 70px;
    }
  }

  &.under-age {
    img {
      max-width: 204px;
    }
  }

  &.filled {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 80px;
      padding-right: 80px;
    }
  }
`
export const Button = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: none;
  background: ${({ disabled }: buttonPorps) => (disabled ? ' #b6b7bb' : '#ff7a00')};
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;

  &:focus {
    outline: none;
  }
`
