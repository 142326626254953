import styled, { keyframes, css } from 'styled-components'
import { device } from 'src/styles/breakpoints'

type PaddingProps = {
  padding?: boolean
}

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`

export const ModalWrapper = styled.div`
  overflow-y: auto;
  position: fixed;
  z-index: 100;
`

export const Modal = styled.div`
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  max-width: 596px;
  background-color: white;
  z-index: 100;
  right: 0;
  top: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.5s ease-in-out;
  animation: ${animatedModal} 0.5s ease-in-out forwards;
  ${(props: PaddingProps) =>
    !props.padding &&
    css`
      padding: 0 40px;
      @media${device.tablet} {
        padding: 0 100px;
      }
    `}
`

export const ModalBackdrop = styled.div`
  height: 100vh;
  width: 100%;
  z-index: 1;
  opacity: 0.4;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: #000;
`
export const CloseButton = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
`
