import React, { FunctionComponent, ComponentClass } from 'react'

import Loadable, { LoadableComponent } from 'react-loadable'
import { LoadingIndicator } from 'inter-site-components'

interface IIcon {
  color: string
  className?: string
  width: number
  height: number
}
type OrangeIconType = {
  size: string
  icon: string
  color: string
  className?: string
  width?: number
  height?: number
}
function OrangeIcon({ size, icon, color, className, width, height }: OrangeIconType) {
  const Icon:
    | (ComponentClass<IIcon> & LoadableComponent)
    | (FunctionComponent<IIcon> & LoadableComponent) = Loadable({
    loader: () => import(`@inter/icons/orangeds/${size}/${icon}`),
    loading: () => <LoadingIndicator size="small" />,
  })
  return <Icon color={color} className={className} width={width || 24} height={height || 24} />
}

export default OrangeIcon
