import React from 'react'

// Assets
import Success from 'src/assets/images/success.png'
import Error from 'src/assets/images/error.png'

// Styles
import { Msg } from './style'

const objectPig = {
  success: Success,
  error: Error,
}

interface IMessageProps {
  status: string
}

interface IInfo {
  [params: string]: {
    title: string
    description: string
  }
}

const Message = ({ status }: IMessageProps) => {
  const info: IInfo = {
    success: {
      title: 'Cadastro recebido',
      description: 'Tudo certo. Você receberá o acesso ao conteúdo em seu e-mail.',
    },
    error: {
      title: 'Cadastro não realizado',
      description: 'Por favor, tente novamente mais tarde.',
    },
  }

  return (
    <Msg className="col-12 text-center pt-4 pt-form-center">
      <h4 className="fs-24 lh-30 mb-2 fw-600">{info[status].title}</h4>
      <div className="col-12 my-3 form-center">
        <img src={objectPig[status]} alt={info[status].title} />
      </div>

      <p className="fs-18 lh-22 pt-3 pt-form-center mb-4">{info[status].description}</p>
    </Msg>
  )
}

export default Message
