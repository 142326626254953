import { useState, useEffect } from 'react'

const useOrigin = (): string => {
  const [origin, setOrigin] = useState('/' || '')
  useEffect(() => {
    setOrigin(window.location.pathname)
  }, [])
  return origin
}

export default useOrigin
