import React, { memo, useEffect } from 'react'

import { Helmet } from 'react-helmet'

import { StyleAdd } from './style'

type AddThisProps = {
  bootstrap?: boolean
}

const AddThis = ({ bootstrap }: AddThisProps) => {
  useEffect(() => {
    if (window.addthis && window.addthis.layers && window.addthis.layers.refresh) {
      try {
        window.addthis.layers.refresh()
      } catch (e) {
        console.error(e)
      }
    }
  }, [])
  if (bootstrap) {
    return (
      <Helmet>
        <script
          async
          defer
          src="https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5fca508a2a7bb7d9"
          type="text/javascript"
        />
      </Helmet>
    )
  }

  return <StyleAdd className="addthis_inline_share_toolbox" />
}

AddThis.defaultProps = {
  bootstrap: false,
}

export default memo(AddThis)
