import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

type IUL = {
  absolute: string
}

export const UL = styled.ul<IUL>`
  position: ${(props) => props.absolute};
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin-right: 18px;
    margin-top: 10px;
    margin-bottom: 10px;

    @media (min-width: ${breakpoints.md}) {
      margin-right: 7px;
    }
    @media (min-width: ${breakpoints.lg}) {
      margin-right: 18px;
    }
  }
`
