import styled from 'styled-components'

import { grayscale } from '../../styles/colors'

export const KnowTheInterContent = styled.div`
  hr {
    border-bottom: 1px dashed ${grayscale['200']};
    margin-top: 25px;
  }
  a {
    text-decoration: none;
    display: block;
    cursor: pointer;

    & + a {
      margin-top: 12px;
    }
  }
`
