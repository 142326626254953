import styled from 'styled-components'

import { grayscale } from '../../styles/colors'

export const WeekHightlightContent = styled.div`
  ul {
    margin: 8px 0 0;
    padding: 0;
    list-style: none;

    li {
      font-family: 'Inter';

      & + li {
        margin-top: 8px;
      }
    }
  }

  hr {
    border-bottom: 1px dashed ${grayscale['200']};
    margin-top: 25px;
  }
`
