import { useState, useEffect } from 'react'

import * as Utility from './utility'

const defaultThrottle = 100

/**
 * Custom hook that gets the current scrolling position
 * arg throttleValue - value for throttling
 * @return scrollPositionY - the current scrolling position
 */
export default function useScroll(throttleValue: number) {
  const [scrollPositionY, setScrollPositionY] = useState(0)
  useEffect(() => {
    const updateScrollValues = Utility.throttle(
      () => {
        const scrollingValue = () => {
          if (window.scrollY && !Number.isNaN(window.scrollY)) {
            return window.scrollY
          }
          return window.window.pageYOffset || window.pageYOffset || 0
        }
        setScrollPositionY(scrollingValue)
      },
      throttleValue === undefined || throttleValue === 0 ? defaultThrottle : throttleValue,
    )

    window.addEventListener('scroll', updateScrollValues, false)
    return () => {
      window.removeEventListener('scroll', updateScrollValues)
    }
  }, [throttleValue])

  return scrollPositionY
}
