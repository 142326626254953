import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerFooter'
import { Link } from 'gatsby'

// Assets

import { WeekHightlightContent } from './style'

type JSONProps = {
  name: string
  slug: string
}

type Props = {
  line?: boolean
  title: string
  data: JSONProps[]
}

const WeekHightlight = ({ line, title, data }: Props) => {
  const [sendDatalayerEvent] = useDataLayer()
  return (
    <WeekHightlightContent>
      <p className="fs-14 lh-17 fw-700 text-grayscale--500">{title}</p>
      <ul>
        {data.map((item: JSONProps) => (
          <li className="fs-12 lh-15" key={item.name}>
            <Link
              to={item.slug}
              title={item.name}
              className="text-grayscale--500"
              onClick={() =>
                sendDatalayerEvent({
                  section: title,
                  element_action: 'click button',
                  element_name: item.name,
                  redirect_url: item.slug,
                })
              }
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
      {line ? <hr /> : ''}
    </WeekHightlightContent>
  )
}

export default WeekHightlight
