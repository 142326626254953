import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'
import { grayscale, white, orange } from 'src/styles/colors'
import { borderRounded } from 'src/styles/radius'

type categoryMenuProp = {
  categoryMenu: boolean
}
export const Logo = styled.div`
  height: 48px;

  img {
    fill: ${(props) => props.theme.colors.primary};
    width: 157px;
  }
`
export const HeaderSection = styled.section`
  border-bottom: 1px solid ${grayscale['200']};
  background-color: ${(props: categoryMenuProp) => (props.categoryMenu ? '#fff' : 'transparent')};
  padding-top: 20px;
  position: fixed;
  width: 100%;
  z-index: 99;

  @media (min-width: ${breakpoints.md}) {
    padding-top: 23px;
    border-bottom: none;
  }

  &.drop-menu {
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
    transition-duration: 0.3s;
    background-color: white;
    .border-md {
      @media (min-width: ${breakpoints.lg}) {
        border-bottom: 0;
      }
    }
  }
  &.menu {
    transition-duration: 0.3s;
  }
  .border-md {
    @media (min-width: ${breakpoints.lg}) {
      padding-bottom: 16px;
      border-bottom: 1px solid ${grayscale['200']};
      @media (min-width: ${breakpoints.md}) {
        padding-bottom: 0;
      }
    }
  }
  .border-bottom-md {
    @media (min-width: ${breakpoints.md}) {
      border-bottom: 1px solid ${grayscale['200']};
    }
    @media (min-width: ${breakpoints.lg}) {
      border: none;
    }
  }
  .search {
    position: absolute;
    z-index: 1;
    right: 5px;
    @media (min-width: ${breakpoints.md}) {
      right: 0;
      position: inherit;
    }
  }
  Button {
    --button-background-color: white;
    --button-text-color: ${orange.base};
    --button-secondary-text-color: white;
    --button-secondary-border-color: white;
    font-weight: 600;
  }
  img {
    width: 153px;
  }
  .search-mobile {
    position: relative;
    padding-right: 4px;
  }
`
export const Nav = styled.nav`
  margin-top: 20px;
  position: relative;

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 0;
    margin-left: 32px;

    @media (min-width: ${breakpoints.md}) {
      margin-left: 0;
    }
  }
  ul {
    margin: 4px 0 0 0;
    padding: 0;
    list-style: none;
    overflow: auto;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: ${breakpoints.lg}) {
      border-bottom: 1px solid ${grayscale['200']};
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      white-space: inherit;
      min-height: 88px;
      margin-top: 10px;
      overflow: hidden;
      border: none;
    }
    li {
      font-family: 'Inter';
      margin-left: 32px;
      font-weight: 700;
      height: 40px;
      display: inline-block;
      color: ${grayscale['500']};

      @media (min-width: ${breakpoints.lg}) {
        margin-left: 0;
        font-weight: normal;
        text-align: center;
        color: ${grayscale['500']};
        display: inline-block;
        margin-left: 0;
      }
      a {
        color: ${grayscale['500']};
        text-decoration: none;
        padding-bottom: 17px;
        @media (min-width: ${breakpoints.md}) {
          display: block;
          span {
            min-height: 38px;
          }
        }
        @media (min-width: ${breakpoints.lg}) {
          padding-bottom: 12px;
        }
      }
      &.active {
        a {
          font-weight: bold;
          border-bottom: 2px solid ${(props) => props.theme.colors.primary};
          &::after {
            height: 4px;
            width: 100%;
            background-color: ${(props) => props.theme.colors.primary};
            border-radius: 2px 2px 0 0;
            content: '';
            display: block;
            margin-top: 14px;
          }
        }
      }
    }
  }
`
export const Button = styled.button`
  align-items: center;
  justify-content: center;
  background: ${orange.base};
  border-radius: ${borderRounded['8']};
  border: 0;
  color: ${white};
  display: flex;
  font-weight: bold;
  height: 49px;
  text-align: center;
  width: 170px;
  font-size: 14px;
  line-height: 17px;

  &:hover {
    opacity: 0.9;
  }

  &.inverse {
    background: ${white};
    color: ${orange.base};
    border: 1px solid #ffb46e;
    &:hover {
      border: 1px solid ${orange.base};
      opacity: 0.9;
    }
  }
`
