import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerFooter'

// hooks
import useWidth from 'src/hooks/useWidth'

// Images
import AppStorePT from 'inter-frontend-svgs/lib/v2/social/store/app-store'
import PlayStorePT from 'inter-frontend-svgs/lib/v2/social/store/google-play'

// Style
import { Wrapper } from './style'

const GatApp = () => {
  const windowWidth = useWidth(360)
  const WIDTH_XS = 359
  const size = windowWidth <= WIDTH_XS ? '130' : '150'
  const [sendDatalayerEvent] = useDataLayer()

  return (
    <Wrapper>
      <span className="fs-16 lh-20 fw-600">Baixe o app</span>
      <div className="d-flex d-lg-block justify-content-start flex-lg-column pb-4 pb-md-0">
        <div className="col-auto col-lg-12 mb-2 mr-1 pl-0">
          <a
            href="https://itunes.apple.com/br/app/banco-inter/id839711154"
            target="_blank"
            rel="noreferrer"
            title="Baixe na App Store"
            aria-label="App Store"
            data-footer="download-app-store"
            onClick={() =>
              sendDatalayerEvent({
                section: '',
                element_action: 'click button',
                element_name: 'App Store',
                redirect_url: 'https://itunes.apple.com/br/app/banco-inter/id839711154',
              })
            }
          >
            <AppStorePT width={size} height="45" />
          </a>
        </div>
        <div className="col-auto col-lg-12 mb-2 pl-0">
          <a
            href="https://play.google.com/store/apps/details?id=br.com.intermedium"
            target="_blank"
            rel="noreferrer"
            title="Baixe no Google Play"
            aria-label="Google Play"
            data-footer="download-google-play"
            onClick={() =>
              sendDatalayerEvent({
                section: '',
                element_action: 'click button',
                element_name: 'Google Pla',
                redirect_url: 'https://play.google.com/store/apps/details?id=br.com.intermedium',
              })
            }
          >
            <PlayStorePT width={size} height="45" />
          </a>
        </div>
      </div>
    </Wrapper>
  )
}

export default GatApp
