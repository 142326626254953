import { css } from 'styled-components'

export const lines = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
}

export function generateEllipsisClasses() {
  return Object.keys(lines).map(
    (line: string) =>
      css`
        .ellipsis-${line} {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: ${line};
          -webkit-box-orient: vertical;
        }
      `,
  )
}
