import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Checkbox } from './style'

type AcceptTermsProps = {
  accept: string | boolean
  setAccept: (value: boolean) => boolean | void
  name?: string
  text?: string
}
function AcceptTerms({ accept, setAccept, name = 'aceite', text }: AcceptTermsProps) {
  const [sendDatalayerEvent] = useDataLayer()
  function handleCheck(event: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = event.currentTarget.checked
    setAccept(inputValue)
  }

  return (
    <Checkbox>
      <input
        name={name}
        value={accept.toString()}
        id={name}
        type="checkbox"
        className="form-input-check"
        onChange={handleCheck}
      />
      <label className="form-label-check fs-14 lh-17" htmlFor={name}>
        {text ||
          'Autorizo o Inter a tratar meus dados para abertura de conta e estou de acordo com a'}
        <a
          className="fw-700"
          title="Acessar Política de Privacidade do Banco Inter"
          href="https://www.inter.co/politica-de-privacidade/privacidade/"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            sendDatalayerEvent({
              section: '',
              element_action: 'click button',
              element_name: 'Política de Privacidade',
              section_name: '',
              redirect_url: 'https://www.inter.co/politica-de-privacidade/privacidade/',
            })
          }}
        >
          {' '}
          Política de Privacidade
        </a>
      </label>
    </Checkbox>
  )
}

export default AcceptTerms
