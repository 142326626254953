import React, { useState, ChangeEvent } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import axios from 'axios'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import AcceptTerms from 'src/components/AcceptTerms'
import OrangeIcon from 'src/components/Icon/OrangeDsIcon'
import { sendCDPFormData } from 'src/shared/cdp'

import Message from '../Message'
// import CheckboxOne from './assets/data/checkbox-one.json'
// import CheckboxTwo from './assets/data/checkbox-two.json'
import {
  Container,
  Button,
  ButtonCancel,
  ButtonSubmit,
  // Check
} from './style'

type ModalNewsletter = {
  email?: string
  setEmail?: React.Dispatch<React.SetStateAction<string>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  page?: string
  category?: string
  subCategory?: string
}

interface IFormValues {
  nome: string
  email: string
  cpfCnpj?: string
}

// type formCheckbox = {
//   text: string
//   active: boolean
//   slug: string
// }

// function concatArray(array: formCheckbox[]) {
//   return array
//     .filter((item: formCheckbox) => item.active)
//     .map((item: formCheckbox) => item.slug)
//     .join(',')
// }

const ModalNewsletter = ({
  email,
  setEmail,
  setOpen,
  page,
  category,
  subCategory,
}: ModalNewsletter) => {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> =
    useForm<IFormValues>()
  const [checkboxOpen, setCheckboxOpen] = useState(false)
  // const [checkboxOne, setCheckboxOne] = useState(CheckboxOne)
  // const [checkboxTwo, setCheckboxTwo] = useState(CheckboxTwo)
  const [accept, setAccept] = useState(false)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')
  const [sendDatalayerEvent] = useDataLayer()

  const sendForm = async (data: IFormValues) => {
    setLoading(true)

    const formData = {
      ...data,
      cpfCnpj: data.cpfCnpj && data.cpfCnpj.replace(/\D/g, ''),
      aceite: accept,
      // conteudo02: `${concatArray(checkboxOne)}${
      //   concatArray(checkboxTwo) !== '' ? ',acoes-e-setores' : ''
      // }`,
      // conteudo03: concatArray(checkboxTwo),
      campanha: 'Inter Invest',
      conteudo01: page,
    }

    try {
      await axios.post(`${URLS.INTER_BROKER_PATH}/lp-mktcloud-inter`, [formData], {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      sendDatalayerEvent({
        section: 'Receba nossas análises por e-mail',
        section_name: 'Receba nossas análises por e-mail',
        element_name: 'continue',
        element_action: 'submit',
        element_previous: '',
        step: 'success',
      })
      sendCDPFormData({
        categoria: category,
        subCategoria: subCategory,
        tituloPagina: document.title,
        formName: 'formulario newsletter',
        cpf: data.cpfCnpj,
        email: data.email,
      })
      setLoading(false)
      setStatus('success')
    } catch (e) {
      setLoading(false)
      setStatus('error')
      sendDatalayerEvent({
        section: 'Receba nossas análises por e-mail',
        section_name: 'Receba nossas análises por e-mail',
        element_name: 'continue',
        element_action: 'submit',
        element_previous: '',
        step: 'error',
      })
    }
  }

  // const handleSelectSubject = (slug: string) => {
  //   const newList = checkboxOne.map((item) => {
  //     if (item.slug === slug) {
  //       return {
  //         ...item,
  //         active: !item.active,
  //       }
  //     }
  //     return item
  //   })
  //   setCheckboxOne(newList)
  // }

  // const handleSelectSubjectTwo = (slug: string) => {
  //   const newList = checkboxTwo.map((item) => {
  //     if (item.slug === slug) {
  //       return {
  //         ...item,
  //         active: !item.active,
  //       }
  //     }
  //     return item
  //   })
  //   setCheckboxTwo(newList)
  // }

  // const handleSelectAll = () => {
  //   const newList = checkboxTwo.map((item) => {
  //     if (checkboxTwo.filter((object) => object.active).length > 0) {
  //       return {
  //         ...item,
  //         active: false,
  //       }
  //     }
  //     return {
  //       ...item,
  //       active: true,
  //     }
  //   })
  //   setCheckboxTwo(newList)
  // }

  return (
    <Container className="py-5">
      {status !== '' ? (
        <Message status={status} />
      ) : (
        <div className="row">
          <div className="col-12">
            <h2 className="fs-20 lh-25 fs-xl-32 lh-xl-40 text-grayscale--500 fw-600">
              Receba nossas análises por e-mail
            </h2>
            <p className="fs-14 lh-17 text-grayscale--400 mb-4">
              Garanta acesso às informações que te ajudarão a potencializar seus investimentos.
            </p>
            <Button onClick={() => setCheckboxOpen(!checkboxOpen)} className="d-flex">
              <div>
                <OrangeIcon
                  icon={checkboxOpen ? 'check-box-on' : 'check-box-off'}
                  size="MD"
                  color="#ff7a00"
                  width={32}
                  height={32}
                />
              </div>
              <p className="fs-14 lh-17 text-grayscale--400 ml-2">
                Sou um assinante institucional (analista, jornalista ou outro profissional do
                mercado)
              </p>
            </Button>
            <form onSubmit={handleSubmit(sendForm)}>
              <div className="row mb-4">
                {!checkboxOpen && (
                  <div className="col-12 mb-4">
                    <label className="fs-14 lh-17 text-grayscale--300" htmlFor="cpfCnpj">
                      CPF
                    </label>
                    <div className="input-icon">
                      <input
                        name="cpfCnpj"
                        type="text"
                        placeholder="Digite somente números"
                        id="cpfCnpj"
                        className="d-block"
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          setValue('cpfCnpj', Masks.MaskCPF(event.currentTarget.value))
                        }
                        ref={register({
                          required: !checkboxOpen && 'Digite um CPF válido',
                          validate: {
                            isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                          },
                        })}
                      />
                      {errors.cpfCnpj && (
                        <p className="fs-12 text-red--base mb-0 text-right">
                          {errors.cpfCnpj.message}
                        </p>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-12">
                  <label className="fs-14 lh-17 text-grayscale--300" htmlFor="nome">
                    Nome completo
                  </label>
                  <input
                    type="text"
                    name="nome"
                    placeholder="Digite seu nome completo"
                    id="nome"
                    className="d-block mb-4"
                    ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) =>
                          Validations.name(value) || 'Por favor, digite seu nome completo',
                      },
                    })}
                  />
                  {errors.nome && (
                    <p className="fs-12 text-red--base mb-0 text-right">{errors.nome.message}</p>
                  )}
                </div>
                <div className="col-12">
                  <label className="fs-14 lh-17 text-grayscale--300" htmlFor="email">
                    E-mail
                  </label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Digite seu e-mail"
                    id="email"
                    className="d-block"
                    onChange={(event) => setEmail && setEmail(event?.target.value)}
                    value={email}
                    ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                  />
                  {errors.email && (
                    <p className="fs-12 text-red--base mb-0 text-right">{errors.email.message}</p>
                  )}
                </div>
              </div>
              {/* <hr className="border" />
              <h2 className="fs-20 lh-25 text-grayscale--500 fw-600 mt-4">
                Por quais assuntos você se interessa?
              </h2>
              <p className="fs-16 lh-19 text-grayscale--400 mb-4">
                Receba notícias somente daquilo que deseja ver
              </p>
              <div className="row">
                {checkboxOne.map((item) => (
                  <div className="col-6">
                    <Button
                      type="button"
                      onClick={() => {
                        handleSelectSubject(item.slug)
                      }}
                      className="d-flex justify-content-center align-items-center mb-4"
                    >
                      <div>
                        <OrangeIcon
                          icon={item.active ? 'check-box-on' : 'check-box-off'}
                          size="MD"
                          color="#ff7a00"
                          width={32}
                          height={32}
                        />
                      </div>
                      <p className="fs-14 lh-17 text-grayscale--400 ml-2 mb-0">{item.text}</p>
                    </Button>
                  </div>
                ))}
              </div>
              <hr className="border-dotted" />
              <Button
                type="button"
                onClick={() => {
                  handleSelectAll()
                }}
                className="d-flex justify-content-center align-items-center"
              >
                <div>
                  {checkboxTwo.filter((item) => item.active).length < checkboxTwo.length &&
                  checkboxTwo.filter((item) => item.active).length > 0 ? (
                    <Check>
                      <span />
                    </Check>
                  ) : (
                    <OrangeIcon
                      icon={
                        checkboxTwo.filter((item) => item.active).length === checkboxTwo.length
                          ? 'check-box-on'
                          : 'check-box-off'
                      }
                      size="MD"
                      color="#ff7a00"
                      width={32}
                      height={32}
                    />
                  )}
                </div>
                <h3 className="fs-16 lh-20 text-grayscale--500 fw-600 ml-2 mb-0">
                  Ações e setores
                </h3>
              </Button>
              <div className="row mt-4 mb-4">
                {checkboxTwo.map((item) => (
                  <div className="col-6">
                    <Button
                      type="button"
                      onClick={() => {
                        handleSelectSubjectTwo(item.slug)
                      }}
                      className="d-flex justify-content-center align-items-center mb-4"
                    >
                      <div>
                        <OrangeIcon
                          icon={item.active ? 'check-box-on' : 'check-box-off'}
                          size="MD"
                          color="#ff7a00"
                          width={32}
                          height={32}
                        />
                      </div>
                      <p className="fs-14 lh-17 text-grayscale--400 ml-2 mb-0">{item.text}</p>
                    </Button>
                  </div>
                ))}
              </div> */}
              <AcceptTerms
                accept={accept}
                setAccept={setAccept}
                text="Ao assinar, você autoriza o envio de comunicação que te ajudarão a potencializar seus rendimentos, e concorda com a nossa Política de Privacidade."
              />
              <div className="row mt-4">
                <div className="col-12 col-md-6 d-none d-md-block">
                  <ButtonCancel
                    type="button"
                    onClick={() => {
                      setOpen(false)
                      sendDatalayerEvent({
                        section: 'Receba nossas análises por e-mail',
                        element_action: 'click button',
                        element_name: 'Cancelar',
                        section_name: '',
                        redirect_url: '',
                      })
                    }}
                  >
                    Cancelar
                  </ButtonCancel>
                </div>
                <div className="col-12 col-md-6">
                  <ButtonSubmit
                    disabled={!accept || loading}
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'Receba nossas análises por e-mail',
                        element_action: 'click button',
                        element_name: 'Cadastrar',
                        section_name: '',
                      })
                    }}
                  >
                    Cadastrar
                  </ButtonSubmit>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </Container>
  )
}

export default ModalNewsletter
