import React, { useState, useRef, ChangeEvent, useEffect, useContext } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerHeader'
import { ThemeContext } from 'styled-components'
import { navigate } from 'gatsby'

import OrangeIcon from '../Icon/OrangeDsIcon'
import { InputText, IconSearch, IconClose } from './style'

type SearchProps = {
  color?: string
}

const Search = ({ color }: SearchProps) => {
  const [input, setInput] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [sendDatalayerEvent] = useDataLayer()

  const inputRef = useRef<HTMLInputElement>(null)
  const { colors } = useContext(ThemeContext)

  const toggleInput = () => {
    setInput(!input)
  }

  const clearInput = () => {
    if (inputRef.current) {
      if (inputRef.current.value === '') {
        setInput(!input)
      } else {
        setInputValue('')
        inputRef.current.focus()
      }
    }
  }

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setInputValue(evt.target.value)
  }
  const handleSearch = (event: { key: string }) => {
    if (event.key !== 'Enter') {
      return
    }
    sendDatalayerEvent({
      section: '',
      element_action: 'click button',
      element_name: 'Busca',
      redirect_url: `${window.location.href}/search?q=${encodeURIComponent(inputValue)}`,
      element_search: inputValue,
    })
    navigate(`/search?q=${encodeURIComponent(inputValue)}`)
  }

  useEffect(() => {
    if (inputRef.current) {
      if (input) {
        inputRef.current.focus()
      }
    }
  }, [input])

  return (
    <>
      {input ? (
        <>
          <InputText
            type="text"
            value={inputValue}
            ref={inputRef}
            onKeyDown={handleSearch}
            onChange={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
          />
          <IconClose onClick={clearInput}>
            <OrangeIcon color={color || colors.primary} icon="clear" size="MD" />
          </IconClose>
        </>
      ) : (
        <IconSearch onClick={toggleInput}>
          <OrangeIcon color={color || colors.primary} icon="search" size="MD" />
        </IconSearch>
      )}
    </>
  )
}

export default Search
